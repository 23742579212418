// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import { AnagraficaDistributoreEdit } from "@/models/anafrafica/AnagraficaDistributore";
import { PartecipanteItem } from "@/models/ricercaGaranzie/partecipanteItem";
import { TextValueExtended, TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component, Watch } from "vue-property-decorator";
import gridComponentBase from "../../../components/grid/gridComponentBase.vue";
import { VNode } from "vue/types/umd";
import { EseguiComandoRequest } from "@/models/comandi/eseguiComandoRequest";
import permessi from "@/config/permessi";
import apiFile from "@/services/apiFile";
import helpers from "@/helpers";
import { Filter } from "@/models/grid/filter";
import moment from "moment";

const _ENDPONT = api.endpoint.RICERCA_GARANZIE;
const _WIDGET_BASE_PATH =  process.env.VUE_APP_WIDGET_FILES_URL;

@Component({})
export default class testWidgetPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Vendite.GARANZIE];
	}

	widgetInitialized: boolean = true;
	inizioViaggio: Date = new Date();
	fineViaggio: Date = new Date();
	destinazioneItem: TextValueExtended = null;
	destinazioni: TextValueExtended[] = [];
	numeroPartecipanti: number = null;
	importoViaggio: number = null;
	partecipanti: any[] = [];

	@Watch("numeroPartecipanti", { immediate: true })
	onNumeroPartecipantiChange(newValue: any, oldValue: string) {
		var currPart = JSON.parse(JSON.stringify(this.partecipanti));
		this.partecipanti = [];

		for(var i = 0; i < newValue; i++){
			var p = currPart[i] ? currPart[i] : {nome: "", cognome: "", dataNascita: null, codiceFiscale: ""};
			this.partecipanti.push(p);
		}
	}

	created()  {
		var self = this;
		if( window["widgetLoaded"]  )
			return;

		api.getDatiDropDown(`${api.endpoint.DROPDOWN.DESTINAZIONI}`).then(res => (self.destinazioni = res as any));

		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = _WIDGET_BASE_PATH + 'webins.widget.js';   

		document.head .appendChild(script);

		var css = document.createElement('link');
		css.rel = "stylesheet";
		css.href =  _WIDGET_BASE_PATH + "css/app.css";    

		document.head.appendChild(css);

		window["widgetLoaded"]  = true;

		setTimeout(() => {
			self.widgetInitialized = false;
		}, 2000);
		

	}


	init(code, datiIniziali, cartCallback){
		this.widgetInitialized = true;
		var wOptions : any = { 
			el: "#webins-widget-container", // Elemento html dove renderizzare il widget
			code:  code,
			//lang: "it", // OPZIONALE - lingua per localizzazione. Se non specificato utilizzata lingua default del browser
			//scope: 3, // OPZIONALE - scope prenotazioni. Se non specificato -> 1 per bike, 2 per vheicle
			// addToCartCallback: (request) => console.log("callback chiamata da widget: " + JSON.stringify(request)), // OPZIONALE - callback aggiunta carrello. Se specificata il widget all'ultimo passaggio demanda lo step di pagamento all'host, passando i dati della prenotazione
			//stepActivatedCallback: (stepId, stepName) => console.log("cambio step widget. step: " + stepId + ", nome: " + stepName) // OPZIONALE - callback cambio step widget
		  }

		if(cartCallback)
		  wOptions.addToCartCallback = cartCallback;
  
		  /*var datiIniziali : any = {
			//inizioViaggio: "2023-10-18", // YYYY-MM-DD
			//fineViaggio: "2023-10-20",
			numeroPartecipanti: 2,
			//dataPrenotazione: "2023-10-18",
			destinazione: 253,
			//fasciaEta: 1, // 1 -> entro 39, 2 -> Entro 70, 3 -> Entro 89, 4 -> Oltre 90
			datiAderente: {
			  nome: "nomeAd",
			  cognome:"cognomeAd",
			  residenza: "estero",
			  dataNascita: "2020-01-01",
			  telefono: "123456",
			  email: "test@test.com",
			  codiceProvincia: "PG",
			  //comune: "054001",
			  citta: "Perugia",
			  cap: "12345",
			  indirizzo: "test"
			},
			assicurati: [
			  { 
				nome: "mario",
				cognome: "Rossi",
				codiceFiscale: "",
				dataNascita: "1970-01-01",
				residenza: "italia"
			  },
			  { 
				nome: "Luigi",
				cognome: "Bianchi",
				codiceFiscale: "123456",
				dataNascita: "1970-03-01",
				residenza: "estero" // italia o estero
			  }
			]
		  }*/
		  //wOptions.datiIniziali = datiIniziali;
		  if(datiIniziali)
		  	wOptions.datiIniziali = datiIniziali;
		  window["initWebinsWidget"](wOptions);
	}

	initWidget(){
		this.init("bc438e0d-ceb5-44a7-bbdd-2f5fee795095", null, null);
	}

	initWidgetNoFasce(){
		this.init("ca6bad23-0250-4d04-8a03-428d5d728520", null, null);
	}

	initWidgetNoFasceNoContraente(){
		this.init("657efbfe-f227-436b-ad06-ba67dc7b68bd", null, null);
	}
	initWidgetB2C(){
		this.init("f9f36c15-ffe4-436f-98b8-b1b9fd2053bd", null, null);
	}
	
	
	initWidgetCarrello(){
		debugger
		var assicurati = this.partecipanti.map((obj) =>{
			var p = JSON.parse(JSON.stringify(obj));
			p.dataNascita && (p.dataNascita =  moment(p.dataNascita).format("YYYY-MM-DD"));
			return p;
		})
		var datiIniziali : any = {
			inizioViaggio: this.inizioViaggio ? moment(this.inizioViaggio).format("YYYY-MM-DD") : null, // YYYY-MM-DD
			fineViaggio: this.fineViaggio ? moment(this.fineViaggio).format("YYYY-MM-DD") : null, // YYYY-MM-DD
			numeroPartecipanti: this.numeroPartecipanti ? parseInt(this.numeroPartecipanti as any) : 0,
			destinazione: this.destinazioneItem ? this.destinazioneItem.code : "",
			assicurati: assicurati,
			importoViaggio: this.importoViaggio || null
		}

		var cartCallback = function(datiAdesione){
			debugger;
			return new Promise((resolve, reject) => {

				setTimeout(() => {
					debugger
					resolve("Polizza aggiunta al carrello con successo! - guid: " + datiAdesione.guidAdesioneTemporanea + " - WidgetCode: f347089a-4b57-4987-934a-fa48e87d71ba" );
				}, 3000);
			});
		}
		//this.init("f347089a-4b57-4987-934a-fa48e87d71ba", datiIniziali, cartCallback);
		this.init("a21eb746-a45e-440f-9235-6a8f19ad9a8e", datiIniziali, cartCallback); // ellerre Prod
	}
}
